import * as React from "react"
import { Carousel, Col } from "react-bootstrap"

import { graphql, PageProps } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import Layout from "../components/layout"
type DataProps = {
  allFile: {
    nodes: [
      {
        name: string
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    ]
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => (
  <Layout pageTitle="Welcome">
    <Carousel interval={3500} fade>
      {data.allFile.nodes.map(node => (
        <Carousel.Item key={node.name}>
          <div className="d-flex justify-content-center">
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              alt={`${node.name} SoundName`}
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
    <div className="d-flex justify-content-center">
      <Col lg="6">
        <p className="text-center">
          SoundNames offers the unique experience of converting your name into
          art, minted as a non fungible token on the Ethereum blockchain.
        </p>
        <p className="text-center">
          Each name card bears its special{" "}
          <a href="https://en.wikipedia.org/wiki/Waveform">waveform</a> that
          represent the sound of the name.
        </p>
      </Col>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    allFile(
      filter: {
        name: { in: ["James", "Mary", "Robert", "Jessica", "Michael"] }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }
    }
  }
`
